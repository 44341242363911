.person-wrapper {
	margin-bottom: 2em;
}

.sidebar-section img {
	margin-bottom: 2em;
}

.profile-pic {
	width: 100%;
}

.btn-primary {
	background-image: none;
	border: none;
	border-radius: 0;
	background-color: #e6ffcc;
	color: gray;
}

h2,
h1 {
	font-weight: bold;
	text-align: center;
	color: #912618;
}

h3,
h4 {
	font-weight: bold;
	text-align: center;
}
h4 {
	color: #912618;
	margin-bottom: 2em;
}

.header-image {
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	height: 50%;
}

.quote {
	font-family: serif;
	font-style: italic;
	font-size: 2em;
}
