.impressumHeader {
	font-size: 14px;
	font-weight: bold;
}

.impressum {
	font-size: 12px;
}

.footer {
	margin-top: 2em;
	margin-bottom: 0;
	max-width: 60%;
	margin-left: 2em;
}
