.italiano {
	font-style: italic;
}

.side-image {
	width: 100%;
}

ul {
	list-style-type: none;
}

p {
	color: black;
}

.jumbotron p {
	font-size: 14px;
}

.profile-pic {
	width: 100%;
	margin-bottom: 2em;
}
