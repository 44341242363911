.navbar-default {
	background-color: #c7fafc;
	border: none;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
	background-color: #ffffff;
}

.navbar {
	position: relative;
	min-height: 50px;
	margin-bottom: 20px;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #ffffff;
}

.header-image {
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
}

.navHead {
	font-size: 80%;
}
