ul {
	list-style-type: disc;
}

.sideText {
	color: black;
}

.jumbotron p {
	font-size: 14px;
}

h3 {
	color: #912618;
}
