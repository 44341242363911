.header-image {
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
}

.about-profile-pic {
	width: 40%;
	float: left;
	margin: 0 1em 1em 0;
}

ul {
	list-style-type: none;
}

.header-text {
	margin-bottom: 0px;
}

.centered {
	text-align: center;
}
