span {
	font-weight: bold;
	color: black;
}

.bottom-bar {
	width: 100%;
}

.plant-pic {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	margin-top: 2em;
}
